import React from 'react';
import styled from 'styled-components';


import datas from './data';

import Project from './Project';

const Div = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 80px 0;
`;

const Projects = () => (
    <Div>
        {datas.map(data => (
            <Project id={data.id} img={data.img} url={"/work/"} title={data.title} />
        ))}
    </Div>
);

export default Projects;