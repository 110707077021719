
import projectImg from '../../images/project.jpg';
import projectImg2 from '../../images/project2.jpg';
import projectImg3 from '../../images/project3.jpg';
import projectImg4 from '../../images/project4.jpg';
import projectImg5 from '../../images/project5.jpg';
import projectImg6 from '../../images/project6.jpg';
const data = [
    {
        id: 1,
        title: "Stepforward",
        img: projectImg,
    },
    {
        id: 2,
        title: "T-FAL",
        img: projectImg2,
    },
    {
        id: 3,
        title: "Stepforward",
        img: projectImg3,
    },
    {
        id: 4,
        title: "Shareshima",
        img: projectImg4,
    },
    {
        id: 5,
        title: "Freenance",
        img: projectImg5,
    },
    {
        id: 6,
        title: "Risto",
        img: projectImg6,
    },
];

export default data;