import React from "react"
import styled from "styled-components"

const A = styled.a`
  background: url(${props => props.img}) no-repeat center/cover;
  height: 300px;
  width: calc(100% / 3 - 20px);
  margin-right: 30px;
  margin-top: 30px;
  border-radius: 20px;
  position: relative;
  &:hover {
    transform: translateY(20px);
    &:before {
      opacity: 1;
    }
    p {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: #00000060;
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }
  &:nth-of-type(3n + 3) {
    margin-right: 0;
  }
  &:nth-of-type(-n + 3) {
    margin-top: 0;
  }
`

const P = styled.p`
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
`
const Project = props => {
  const { id, img, url, title } = props
  return (
    <A id={id} img={img} href={url}>
      <P>{title}</P>
    </A>
  )
}

export default Project
