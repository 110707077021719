import React, { Suspense, lazy } from "react"
import Layout from "../components/Layout"

import { Wrapper } from "../components/Wrapper"

import Projects from "../components/Projects"

const Works = () => (
  <Layout>
    <Wrapper>
        <Projects />
    </Wrapper>
  </Layout>
)

export default Works
